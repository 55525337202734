import esEvent from '~/assets/js/eSputnik/esEvent';

export default function productPageEvent(data, selectedSize) {
    const eventName = 'ProductPage';

    if (typeof data !== 'object' && typeof selectedSize !== 'object') {
        console.warn('Wrong data type', eventName, data, selectedSize);
    }

    const selectedProductId = (selectedSize && selectedSize.id) ? selectedSize.id : data?.sizes?.[0]?.id;
    if (selectedProductId) {
        generateDataEvent();
    } else {
        console.warn('No product ID', eventName, selectedProductId);
    }

    function generateDataEvent() {
        const eventBody = {
            'productKey': selectedProductId.toString(),
            'price': data?.price?.sale.toString(),
            'isInStock': 1,
        };

        sendEvent({
            [eventName]: eventBody
        });
    }

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
