//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductContent from "@/components/pages/productCard/ProductContent/index";
import ProductSidebar from "@/components/pages/productCard/ProductSidebar/index";
import {mapGetters} from "vuex";
import productPageEvent from '~/assets/js/eSputnik/productPageEvent';
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "ProductCard",
    components: {
        ProductSidebar,
        ProductContent,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    computed: {
        ...mapGetters({
            widgetsDataLoading: 'dynamicPage/getWidgetsDataLoading',
            selectedSize: 'productView/getChosenSize',
        }),
        isDesktop() {
            return this.laptopSmallUp;
        }
    },

    data() {
        return {
            timeOut: null,
            sidebarStyle: {
                top: 0,
                bottom: 0,
            },
            scrollPosition: 0,
            lastDirection: 0,
        }
    },
    mounted() {
        this.fadeIn();
        window.addEventListener('scroll', this.stickyScroll);
        window.addEventListener('scroll', this.fadeIn);
        if(process.browser){
            this.product?.sizeChart && this.$store.dispatch('productView/getSizeGuideInfo', this.product.sizeChart);
            productPageEvent(this.product, this.selectedSize);
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
        window.removeEventListener('scroll', this.stickyScroll);
        window.removeEventListener('scroll', this.fadeIn);
    },
    methods: {
        stickyScroll() {
            let scroll = window.scrollY;
            let direction = this.scrollPosition > scroll ? 1 : -1;
            let sections = document.getElementsByClassName('scroll-section'),
                header = document.getElementsByClassName('header')[0].offsetHeight,
                product = document.getElementsByClassName('product-card')[0],
                sidebar = document.getElementsByClassName('product__sidebar__inner')[0],
                chooseSize = document.getElementsByClassName('choose-size')[0],
                nav = document.getElementsByClassName('navigation')[0],
                menuList = document.getElementsByClassName('navigation__list')[0],
                menuItems = document.getElementsByClassName('navigation__list--item'),
                windowHeight = window.innerHeight;
            sections.forEach(i => {
                let top = i.offsetTop,
                    bottom = top + i.offsetHeight,
                    id = i.getAttribute('id');
                if (scroll > top && scroll < bottom) {
                    menuItems.forEach(el => {
                        if (el.dataset.anchor === id) {
                            el.classList.add('active');
                            let left = el.offsetLeft - 10;
                            menuList.scrollTo({
                                left: left,
                                behavior: 'smooth'
                            })

                        } else {
                            el.classList.remove('active');
                        }
                    })
                }
            });

            if (nav) {
                if (scroll >= nav.offsetTop + header) {
                    nav.classList.add('fixed');

                    if (scroll >=  product.offsetHeight + header - 2*nav.offsetHeight) {
                        nav.classList.add('hide');
                    } else {
                        nav.classList.remove('hide');
                    }
                } else {
                    nav.classList.remove('fixed');
                }
            }
            if (chooseSize) {
                if (chooseSize && scroll >= nav.offsetTop + header && scroll <= product.offsetHeight + header - windowHeight) {
                    chooseSize.classList.add('show');
                } else {
                    chooseSize.classList.remove('show');
                }
            }

            if (sidebar) {
                let sidebarOver = sidebar.offsetHeight - windowHeight > 0 ? sidebar.offsetHeight - windowHeight : 0;
                let setFixedPosition = () => {
                    this.sidebarStyle.top = '0px';
                    sidebar.classList.remove('fixed-bottom', 'stop-sticky');
                    sidebar.classList.add('fixed')
                }
                let setFixedBottomPosition = () => {
                    this.sidebarStyle.top = 'auto';
                    this.sidebarStyle.bottom = '0';
                    sidebar.classList.remove('fixed', 'stop-sticky');
                    sidebar.classList.add('fixed-bottom');
                }
                if (scroll > header  && scroll <= product.offsetHeight + header - sidebar.offsetHeight + sidebarOver) {
                    if (sidebarOver && (this.lastDirection !== 0 && this.lastDirection !== direction || sidebar.classList.contains('stop-sticky'))) {
                        if (this.lastDirection !== direction && !sidebar.classList.contains('stop-sticky')) {
                            this.sidebarStyle.top = scroll - header + sidebar.offsetTop + 'px';
                            sidebar.classList.remove('fixed','fixed-bottom');
                            sidebar.classList.add('stop-sticky');
                        } else {
                            if (direction >= 0 && scroll <= header + sidebar.offsetTop) {
                                setFixedPosition();
                            } else if (direction < 0 && scroll >= header + sidebar.offsetTop + sidebarOver) {
                                setFixedBottomPosition();
                            }
                        }
                    } else if (direction <= 0 && sidebarOver > 0 && scroll > header + sidebarOver) {
                        setFixedBottomPosition();
                    } else if ((direction > 0 && sidebarOver > 0) || scroll > header + sidebarOver ) {
                        setFixedPosition();
                    }
                } else if (scroll > product.offsetHeight + header - sidebar.offsetHeight + sidebarOver) {
                    this.sidebarStyle.top = 'auto';
                    sidebar.classList.remove('fixed', 'fixed-bottom');
                    sidebar.classList.add('stop-sticky')
                } else {
                    sidebar.classList.remove('fixed', 'fixed-bottom');
                }

            }
            this.scrollPosition = scroll;
            this.lastDirection = direction;
        },
        fadeIn() {
            let scrollTop = window.scrollY + window.innerHeight,
                sections = document.querySelectorAll('.fade-in');

            sections.forEach(i => {
                let top = i.offsetTop + 200;

                if (scrollTop > top && !i.classList.contains("show")) {
                    i.classList.add("show")
                }
            });
        },
    },
    watch: {
        product: {
            handler(newVal, oldVal){
                let newValId = (newVal?.id ?? null),
                    oldValId = (oldVal?.id ?? null);
                if(newVal && newValId !== oldValId){
                    if (process.browser) {
                        this.$gtag('event', 'view_item', {
                            value: this.product.price.sale,
                            items: this.product.sizes.map(i => {
                                return {
                                    id: i.id,
                                    google_business_vertical: 'retail'
                                }
                            })
                        });

                        this.timeOut = setTimeout(function (){
                            fbq('track','ViewContent',{
                                content_type: 'product',
                                value: this.product.price.sale,
                                currency: 'USD',
                                content_ids: [this.product.id],
                            });
                        }.bind(this), 1000)
                    }
                }
            },
            immediate: true
        },
        selectedSize: {
            handler(newVal, oldVal){
                if( newVal.id !== oldVal.id){
                    productPageEvent(this.product, this.selectedSize)
                }
            },
        }
    }
}
